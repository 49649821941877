


























import { Vue, Component, Prop } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import SignaturePad from "signature_pad";
import { GlobalActions } from "@/shared/store/global/global.actions";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import { getNodeApiUrlByVersion } from "@/config";
import baseHttp from "@/shared/http";

@Component({
  methods: { __ },
})
export default class SignaturePadModal extends Vue {
  @Prop() meta: any
  http = baseHttp
  signaturePad: SignaturePad | null = null;
  canvasWidth: string = this.isCanvasWidth()
  canvasHeight: string = "300px";

  mounted() {
    this.signaturePad = new SignaturePad(this.canvas, {
      backgroundColor: "transparent",
      penColor: "black",
      minWidth: 1,
      maxWidth: 2,
    });
    this.windowResize();
  }

  private windowResize(): void {
    window.addEventListener("resize", () => {
      this.clear();
      return this.canvasWidth = window.innerWidth < 960 ? Math.floor(window.innerWidth * 0.8) + "px" : '800px'
    });
  }

  private isCanvasWidth(): string {
    if (window.innerWidth < 960) return Math.floor(window.innerWidth * 0.8) + "px"
    return '800px'
  }

  private get canvas(): HTMLCanvasElement {
    return this.$refs.canvas as HTMLCanvasElement;
  }

  private clear() {
    this.signaturePad?.clear();
  }

  private isEmpty(): boolean {
    return this.signaturePad?.isEmpty() ?? true;
  }

  public download() {
    if (this.signaturePad && !this.isEmpty()) {
      const dataURL = this.signaturePad.toDataURL();
      const byteString = atob(dataURL.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: 'image/png' });

      const imgData = new FormData()
      imgData.append("file", blob);

      this.http.patch(
        `${getNodeApiUrlByVersion()}/hse-handbook-signatures/sign/${this.meta.handbookUuid}`,
        imgData
      )
        .then((res) => {
          if (res.status === 200) {
            this.meta.signStatusHandler()
            this.closeDialog();
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __("company.views.hse-handbook.sign.success"),
            });
          }
        });
    } else if (this.isEmpty()) {
      this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.error,
        message: __("company.views.hse-handbook.sign.error"),
      });
    }
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog);
  }
}
