


































import { Component, Vue, Prop } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import FormField from "@/shared/components/form/FormField.vue";
import Form from "@/shared/components/form/Form.vue";
import SignaturePadModal from '@/shared/components/signature-pad-modal/SignaturePadModal.vue'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import { formatDate } from '@/shared/helpers/formatDate.helper';

@Component({
  components: { PageHeader, FormField, Form, SignaturePadModal },
  methods: { __ },
})
export default class HseHandbookSign extends Vue {
  @Prop() meta: any

  get user(): User {
    return this.$store.getters[AuthGetters.getUser];
  }

  get date(): string {
    return formatDate();
  }
}
