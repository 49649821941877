













































import { Component, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import FormBase from "@/shared/classes/form/form-base";
import FormField from "@/shared/components/form/FormField.vue";
import { GlobalActions } from "@/shared/store/global/global.actions";
import Form from "@/shared/components/form/Form.vue";
import PdfViewer from "@/shared/components/pdf-viewer/PdfViewer.vue";
import HseHandbookSign from "@/company/components/hse-handbook/HseHandbookSign.vue";
import { getNodeApiUrlByVersion } from "@/config";
import baseHttp from "@/shared/http";
import { AuthRoutes } from "@/shared/router/auth";
import localeHelper from "@/shared/helpers/locale.helper";

@Component({
  components: { PageHeader, FormField, Form, PdfViewer },
  methods: { __ },
})
export default class AcknowledgementSigningHseHandbook extends Vue {
  http = baseHttp;
  form: FormBase | null = null;
  url: null | string = null;
  signBtnVisible = window.screen.width <= 960;
  alreadySigned = true;
  handbookUuid = '';

  created() {
    if (!localStorage.getItem("authToken")) {
      localStorage.setItem('redirectAfterLogin', this.$route.path);
      return this.$router.push({ name: AuthRoutes.login });
    }

    this.form = new FormBase()
      .setTranslatable(true)
      .setSubmit(false);

    this.getPdfData(this.$route.params.uuid, localeHelper.getLocale())

    this.$watch('form.currentLanguage', () => {
      this.getPdfData(this.$route.params.uuid, this.form?.currentLanguage || 'lt')
    })

    // Check if user signed this handbook
    this.http.get(`${getNodeApiUrlByVersion()}/hse-handbook-signatures/for-handbook/${this.$route.params.uuid}`)
      .then(res => {
        if (res.data?.status !== 2) this.alreadySigned = false;
      });
  }

  getPdfData(handbookUuid: string, lang: string) {
    this.form?.setTranslatableLoading(true);
    this.http.post(`${getNodeApiUrlByVersion()}/hse-handbook/print`,
      { hseHandbookUuid: handbookUuid },
      { responseType: 'blob', headers: { 'Accept-Language': lang } }
    )
      .then(res => this.url = window.URL.createObjectURL(res.data))
      .finally(() => {
        this.form?.setTranslatableLoading(false);
      })
  }

  setSignBtnVisible(value: boolean) {
    this.signBtnVisible = value;
  }

  openCreateDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: HseHandbookSign,
      maxWidth: 1100,
      persistent: true,
      meta: {
        handbookUuid: this.$route.params.uuid,
        signStatusHandler: () => this.alreadySigned = true,
      }
    });
  }
}
