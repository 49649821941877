










import { Component, Prop, Vue } from "vue-property-decorator";
import cafePdf from "custom-cafe-pdf";

@Component({
  components: {
    cafePdf,
  },
})
export default class PdfViewer extends Vue {
  @Prop() pdfUrl!: string;
  @Prop() setSignBtnVisible!: (value: boolean) => void;
  scale: any = 'auto'

  mounted() {
    const pdfContainer = document.querySelector(".pdf-container") as HTMLElement;

    pdfContainer!.addEventListener("scroll", (e: any) => {
      const scrollHeight = e!.target!.scrollHeight - e!.target.clientHeight;
      const scrollY = e!.target.scrollTop;

      if (scrollHeight - scrollY < 100) this.setSignBtnVisible(true)
      else this.setSignBtnVisible(false);
    });
  }
}
