import moment from "moment";
import { getVersion } from "@/shared/helpers/version.helper";

const noFormat = 'DD-MM-YYYY';
const ltFormat = 'YYYY-MM-DD';

export const formatDate = (date?: Date) => {
  const format = getVersion() === 'no' ? noFormat : ltFormat;

  return moment(date).format(format)
}
